// import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs'
import React, { useContext, useEffect, useState } from 'react'
import Container from '../components/atoms/Container/Container';
import ContentfulContext from '../context/ContentfulProvider';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import FaqChatWithUs from '../components/molecules/FaqChatWithUs/FaqChatWithUs';
import PageBannerPolicy from '../components/atoms/PageBannerPolicy/PageBannerPolicy';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import * as styles from './faqs.module.css';

const FaqCategoryIcon = ({ faqItem, children }) => {
    const [onHover, setOnHover] = useState(false);
     
    return (
        <div className={styles.faqCategoryContainer} role="presentation"
            onMouseEnter={() => setOnHover(true)} 
            onMouseLeave={() => setOnHover(false)} 
        >
            <div className={onHover ? styles.faqCategoryIcon : styles.faqCategoryIconHidden} 
                style={{backgroundImage: `url(${faqItem.categoryIconHover.file.url})`}}
            >
            </div>
            <div className={onHover ? styles.faqCategoryIconHidden : styles.faqCategoryIcon} 
                style={{backgroundImage: `url(${faqItem.categoryIcon.file.url})`}}
            >
            </div>
        </div>
    )
}

const FaqPage = () => {
    const contentful = useContext(ContentfulContext);
    const faqPage = contentful ? contentful.faqPage : '';
    const [faqsCategories, setFaqsCategories] = useState([]);

    useEffect(() => {
      if (contentful.faqsCategories) {
        const faqsCategories = contentful.faqsCategories?.edges?.reduce((acc, current) => {
          acc.push(current.node);
          return acc;
        }, []).sort((a, b) => a.order - b.order);
        setFaqsCategories(faqsCategories);
      }

    }, [contentful?.faqsCategories]);

    return (
        <Layout>
            {faqPage.pageName &&
              <Seo title={faqPage.pageName} description={faqPage.metaDescription} image={faqPage.metaPreviewImage ? `https:${faqPage.metaPreviewImage.file.url}` : ''}/>
            }
            <PageBannerPolicy 
                title={faqPage.pageTitle}
                description={faqPage.metaTitle}
            />
            <Container size="medium">
              <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {label:'Q & A'}]}/>
                <div className={styles.faqBody}>
                    <p className='pb-6'>{faqPage.metaDescription}</p>
                    
                    <h4 className='pb-5'>Select a topic</h4>
                    <div className={styles.faqCategoriesWrapper}>
                    {faqsCategories && faqsCategories.map((faqItem, faqItemKey) => {
                        return (
                            <div className={styles.faqCategories} key={`faq-category-container-${faqItemKey}`}>
                                <a href={`/faqs/${faqItem.slug.replace(/^\/|\/$/g, '')}`}>
                                    <FaqCategoryIcon  faqItem={faqItem} />
                                    <div className={styles.faqCategoryName}>{faqItem.name}</div>
                                </a>
                            </div>
                        );
                    })}
                    </div>
                </div>
            </Container>
            
            <FaqChatWithUs background="true" />

        </Layout>
    );
}
 
export default FaqPage;